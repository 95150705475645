export const companyReportData = {
  reported_at_date: "2024-08-08T10:24:56.845944+01:00",
  company_details: {
    company_name: "Acme limited and Co",
    status: "Active",
    company_number: "1234567",
    registered_address:
      "Acme House, Yiewsley, West Drayton, Middlesex, UB7 7BD",
  },
};

export const companyReportSummary = {
  registration_date: "2023-09-06T10:24:56.845944+01:00",
  status: "Active",
  company_number: "1234567",
  employee_count: 600,
  registered_address: "109 Carr Forge Road, Sheffield South Yorkshire, S12 4FJ",
  type: "Private limited with Share Capital",
  vat_numbers: ["GB123456789"],
  account_filing_date: "2023-06-08T10:24:56.845944+01:00",
  share_capital: {
    currency: "GBP",
    value: 1,
  },
  websites: ["www.acme.com"],
  principal_activity: "Unknown",
  cic03_activities: "5242 - Retail sale of clothing",
  sic07_activites: "47710 - Retail sale of clothing in specialised stores",
};
